<template>
  <div>
    <BreadCrumb :items="breadCrumb" />
    <div class="row">
      <div class="col">
        <b-card>
          <h2>Test Information</h2>
          <hr />
          <div class="mt-4">
            <h5>Book Information:</h5>
            <BookSnippet :book="book" :toLink="{name:'book-preview', params:{book_id}}" />
          </div>
          <div class="mt-4">
            <h5>Possible Test Score:</h5>
            <p>Number of points you will get if you complete all questions successfully: <span class="font-weight-bold lead">50</span> points</p>
          </div>
          <div class="mt-4">
            <h5>Past Test Scores:</h5>
            <p>Your previous scores in this book are listed below.</p>
            <ul class="list-group">
              <li class="list-group-item">
                <div class="media flex-column flex-sm-row">
                  <span class="text-muted mr-3">05.06.2020</span>
                  <div class="media-body">
                    <div>
                      <span>Score: 20</span>
                      <b-link class="ml-3" to="">View</b-link>
                    </div>
                    <div class="text-muted">
                      <span class="mr-2">Time spent: 10 minutes</span>
                      <span class="mr-2">Correct answers: 7</span>
                      <span class="mr-2">Wrong answers: 5</span>
                      <span class="mr-2">Not answered: 3</span>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="media flex-column flex-sm-row">
                  <span class="text-muted mr-3">30.05.2020</span>
                  <div class="media-body">
                    <div>
                      <span>Score: 16</span>
                      <b-link class="ml-3" to="">View</b-link>
                    </div>
                    <div class="text-muted">
                      <span class="mr-2">Time spent: 10 minutes</span>
                      <span class="mr-2">Correct answers: 4</span>
                      <span class="mr-2">Wrong answers: 6</span>
                      <span class="mr-2">Not answered: 5</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="mt-4">
            <h5>Overall Score:</h5>
            <p>
              Your overall score in this session: <span class="font-weight-bold">270</span> points.
              <b-link class="ml-3" to="">View details</b-link>
            </p>
          </div>
          <hr />
          <div class="text-right">
            <button class="btn btn-sm btn-outline-secondary my-2" @click="$router.push({name:'sampletest-instruction'})" title="Try some practice questions">Try a Sample Test</button>
            <button class="btn btn-sm btn-outline-primary ml-3" @click="$router.push({name:'test-instruction', params:{book_id}})" title="Test yourself with questions from this book">Take the Book Test</button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import BookSnippet from '@/views/public/book/components/BookSnippet.vue';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import getBookMixin from '@/mixins/getbook';

export default {
  name: 'TestInformation',
  mixins: [getBookMixin],
  components: { BookSnippet, BreadCrumb },

  data() {
    return {
      book_id: this.$route.params.book_id,
      book: null,
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books Categories', to: { name: 'book-cat-list' } },
        { text: (this.book ? this.book.title : this.book_id), to: { name: 'book-preview', params: { book_id: this.book_id } } },
        { text: 'Test', active: true },
      ];
    },
  },

};
</script>
